var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs'),_c('v-container',{class:{
      'mt-3 mb-2': _vm.$vuetify.breakpoint.smAndDown,
      'mt-5 mb-5': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('base-subheading',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.about_us_page.title"))+" ")]),(_vm.$te('pages_specific.about_us_page.p'))?_c('div',{staticClass:"mt-5"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.about_us_page.p[0]"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.about_us_page.p[1]"))+" ")]),_c('div',{staticClass:"pb-4 text-center mt-7 mb-3"},[_c('div',{staticClass:"d-inline-block"},[_c('img',{staticClass:"pr-8",attrs:{"alt":_vm.$t('pages_specific.about_us_page.text_president'),"title":_vm.$t('pages_specific.about_us_page.text_president'),"src":require('@/assets/' +
                _vm.$t('pages_specific.about_us_page.image_president'))}}),_c('div',[_vm._v(_vm._s(_vm.$t("pages_specific.about_us_page.text_president")))])]),_c('div',{staticClass:"d-inline-block"},[_c('img',{attrs:{"alt":_vm.$t('pages_specific.about_us_page.text_dg'),"title":_vm.$t('pages_specific.about_us_page.text_dg'),"src":require('@/assets/' +
                _vm.$t('pages_specific.about_us_page.image_dg'))}}),_c('div',[_vm._v(_vm._s(_vm.$t("pages_specific.about_us_page.text_dg")))])])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("pages_specific.about_us_page.p[2]"))+" ")])]):_vm._e()],1),_c('v-divider'),_c('v-container',{staticClass:"mb-6",class:{
      'mt-3': _vm.$vuetify.breakpoint.smAndDown,
      'mt-5': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('visit-card')],1)],1)],1),_c('v-divider'),_c('iframe',{attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.6904668253214!2d7.747568315619319!3d48.57747697926088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c8fc0c0d480f%3A0x8123fe6cb0af676f!2sStrataggem!5e0!3m2!1sen!2sfr!4v1611167342451!5m2!1sen!2sfr","width":"100%","height":"300","frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }