// The object structure, as opposed to a simple list of identifiers,
// is retained so that we can add unique elements for each product,
// such as icons (as is the case for services) or images.
const productsCategories = [
  {
    id: "example_product_1",
    children: [
      {
        id: "example_sub_product_1"
      }
    ]
  },
  {
    id: "example_product_2",
  },
];

export { productsCategories };
