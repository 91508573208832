<template>
  <div>
    <breadcrumbs />

    <v-container
      :class="{
        'mt-3 mb-2': $vuetify.breakpoint.smAndDown,
        'mt-5 mb-5': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <base-subheading class="display-1">
        {{ $t("pages_specific.about_us_page.title") }}
      </base-subheading>

      <div class="mt-5" v-if="$te('pages_specific.about_us_page.p')">
        <p>
          {{ $t("pages_specific.about_us_page.p[0]") }}
        </p>

        <p>
          {{ $t("pages_specific.about_us_page.p[1]") }}
        </p>

        <div class="pb-4 text-center mt-7 mb-3">
          <div class="d-inline-block">
            <img
              class="pr-8"
              :alt="$t('pages_specific.about_us_page.text_president')"
              :title="$t('pages_specific.about_us_page.text_president')"
              :src="
                require('@/assets/' +
                  $t('pages_specific.about_us_page.image_president'))
              "
            />
            <div>{{ $t("pages_specific.about_us_page.text_president") }}</div>
          </div>

          <div class="d-inline-block">
            <img
              :alt="$t('pages_specific.about_us_page.text_dg')"
              :title="$t('pages_specific.about_us_page.text_dg')"
              :src="
                require('@/assets/' +
                  $t('pages_specific.about_us_page.image_dg'))
              "
            />
            <div>{{ $t("pages_specific.about_us_page.text_dg") }}</div>
          </div>
        </div>

        <p>
          {{ $t("pages_specific.about_us_page.p[2]") }}
        </p>
      </div>
    </v-container>

    <v-divider />

    <v-container
      class="mb-6"
      :class="{
        'mt-3': $vuetify.breakpoint.smAndDown,
        'mt-5': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-row>
        <v-col cols="12" md="4">
          <visit-card />
        </v-col>
      </v-row>
    </v-container>

    <v-divider />
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.6904668253214!2d7.747568315619319!3d48.57747697926088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c8fc0c0d480f%3A0x8123fe6cb0af676f!2sStrataggem!5e0!3m2!1sen!2sfr!4v1611167342451!5m2!1sen!2sfr"
      width="100%"
      height="300"
      frameborder="0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
    />
  </div>
</template>
  
<script>
import InfoInContainer from "../components/app_specific/TitleTextListTextTransformedFromLocalesInContainer.vue";

export default {
  components: {
    VisitCard: () => import("@/components/contact/VisitCard"),
    Breadcrumbs: () => import("@/components/core/Breadcrumbs.vue"),
    InfoInContainer,
  },
};
</script>