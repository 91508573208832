<template>
  <v-card
    tile
    v-bind="$attrs"
    v-on="$listeners"
    :elevation="outlined || elevation_0 ? 0 : 3"
    :outlined="outlined"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",

  props: {
    outlined: {
      default: true,
      type: Boolean,
    },
    elevation_0: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
