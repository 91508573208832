<template>
  <v-container
    :class="{
      'mt-3 mb-2': $vuetify.breakpoint.smAndDown,
      'mt-5 mb-5': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <title-text-list-text-transformed-from-locales
      :path_in_translations="path_in_translations"
      :large_header="large_header"
    />
  </v-container>
</template>

<script>
export default {
  props: {
    path_in_translations: {
      type: String,
      default: "",
    },
    large_header: Boolean,
  },

  components: {
    TitleTextListTextTransformedFromLocales: () => import("@/components/app_specific/TitleTextListTextTransformedFromLocales"),
  },
};
</script>