<template>
  <div class="row mt-5">
    <div class="col-lg-4 text-left mt-3" v-if="!is_image_on_right">
      <img
        :class="{
          'limit-height-14':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'limit-height-10': $vuetify.breakpoint.xsOnly,
          'pr-5': $vuetify.breakpoint.lgAndUp,
        }"
        :alt="$t(path_in_translations + '.title')"
        :title="$t(path_in_translations + '.title')"
        :src="require('@/assets/' + $t(path_in_translations + '.image'))"
      />
    </div>

    <div class="col-lg-8 desc">
      <base-subheading>{{
        $t(path_in_translations + ".title")
      }}</base-subheading>

      <div
        :class="{
          'mt-5 mb-0': $vuetify.breakpoint.smAndDown,
          'mt-5 mb-7': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <p v-for="(item, i) in $t(path_in_translations + '.p')" :key="'p_' + i">
          {{ item }}
        </p>

        <div v-if="$te(path_in_translations + '.p_small_text')">
          <p
            class="mb-0 text-body-2"
            v-for="(item, i) in $t(path_in_translations + '.p_small_text')"
            :key="'ps_' + i"
          >
            {{ item }}
          </p>
        </div>
      </div>

      <v-btn
        v-if="link_url && null != link_url"
        large
        color="dark"
        class="text-none font-large accent"
        :class="{
          'mt-5 mb-3': $vuetify.breakpoint.smAndDown,
          'mb-6': $vuetify.breakpoint.lgAndUp,
        }"
        text
        :to="link_url"
      >
        {{ $t("global.learn_more") }}
      </v-btn>
    </div>

    <div
      class="col-lg-4 mt-3"
      v-if="is_image_on_right"
      :class="{
        'text-right': $vuetify.breakpoint.lgAndUp,
      }"
    >
      <img
        :class="{
          'limit-height-14':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'limit-height-10': $vuetify.breakpoint.xsOnly,
          'pl-5': $vuetify.breakpoint.lgAndUp,
        }"
        :alt="$t(path_in_translations + '.title')"
        :title="$t(path_in_translations + '.title')"
        :src="require('@/assets/' + $t(path_in_translations + '.image'))"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path_in_translations: {
      type: String,
      default: "",
    },
    link_url: {
      type: String,
      default: "",
    },
    is_image_on_right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
li {
  line-height: 2em;
}
</style>
