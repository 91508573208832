<template>
  <div>
    <breadcrumbs />
    <!-- The page specfic content should be placed here -->

    <v-container>
      <v-list class="min-content-width">
        <v-list-item
          two-line
          href="https://wiki.strataggem.com/doku.php"
          target="_blank"
        >
          <v-list-item-content>
            <v-list-item-title
              class="text-decoration-underline"
              v-text="$t('pages_specific.docs_page.docu_wiki')"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          two-line
          href="https://github.com/strataggem"
          target="_blank"
        >
          <v-list-item-content>
            <v-list-item-title
              class="text-decoration-underline"
              v-text="$t('pages_specific.docs_page.public_git')"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
export default {
  components: {
    Breadcrumbs,
  },
};
</script>