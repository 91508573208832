var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs'),_c('v-container',{class:{
      'mt-3 mb-2 px-5': _vm.$vuetify.breakpoint.smAndDown,
      'mt-5 mb-15': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('base-subheading',{staticClass:"mb-10 display-1"},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.realizations_page.range.title"))+" ")]),_c('text-floating-image',{staticClass:"mt-5",attrs:{"image_path":_vm.$t('pages_specific.realizations_page.range.details_page.part_1.image'),"paragraphs":_vm.$t('pages_specific.realizations_page.range.details_page.part_1.p')}}),_c('div',{staticClass:"row"},_vm._l((_vm.$t(
          'pages_specific.realizations_page.range.details_page.part_2.cols'
        )),function(col,i){return _c('div',{key:'c' + i,staticClass:"mt-10 col-md-4 px-md-9",class:{ 'text-justify': _vm.$vuetify.breakpoint.mdAndUp }},[_c('base-subheading',{staticClass:"mb-10",class:{ 'text-center': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" "+_vm._s(col.title)+" ")]),_c('text-floating-image',{staticClass:"mt-5",attrs:{"image_path":col.image,"paragraphs":col.p,"disable_float":true}})],1)}),0),_c('div',{staticClass:"mt-10"},[_c('base-subheading',{staticClass:"mb-10 display-1"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.realizations_page.range.details_page.part_3.title" ))+" ")]),_vm._l((_vm.$t(
          'pages_specific.realizations_page.range.details_page.part_3.p'
        )),function(p,i){return _c('p',{key:'p3' + i,domProps:{"innerHTML":_vm._s(p)}})})],2)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }