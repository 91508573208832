<template>
  <div>
    <breadcrumbs />
    <!-- The page specfic content should be placed here -->
    <title-text-list-text-transformed-from-locales-in-container
      :path_in_translations="path_in_translations"
      :large_header="true"
    />
  </div>
</template>

<script>
import TitleTextListTextTransformedFromLocalesInContainer from "../components/app_specific/TitleTextListTextTransformedFromLocalesInContainer.vue";
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
export default {
  props: {
    path_in_translations: {
      type: String,
      default: "",
    },
  },

  components: {
    Breadcrumbs,
    TitleTextListTextTransformedFromLocalesInContainer,
  },
};
</script>