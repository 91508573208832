<template>
  <div>
    <v-container class="mb-0">
      <v-breadcrumbs :items="breadcrumbs" divider=">" />
    </v-container>
    <v-divider />
  </div>
</template>
  
<script>
export default {
  data: () => ({
    breadcrumbs: [],
  }),

  watch: {
    "$i18n.locale": function () {
      this.updateBreadCrumps();
    },

    $route() {
      this.updateBreadCrumps();
    },
  },

  mounted() {
    // this update is needed because at the moment 
    // $route changed event was treated, 
    // the component could be still in the creation process, 
    // and the breadcrumbs would resolve to []
    this.updateBreadCrumps();
  },

  methods: {
    updateBreadCrumps() {
      const home_page = {
        text: this.$t("global.pages_titles.home"),
        disabled: false,
        to: "/",
      };
      const parent_link_if_any = this.getParentLinkIfAny();
      const parent_page =
        null != parent_link_if_any
          ? {
              text: this.getLinkText(parent_link_if_any),
              disabled: false,
              to: parent_link_if_any.path,
              exact: true
            }
          : null;

      const current_link = this.getCurrentLink();
      const current_page = {
        text: this.getLinkText(current_link),
        disabled: true,
      };
      this.breadcrumbs = [];
      this.breadcrumbs.push(home_page);
      if (null != parent_page) {
        this.breadcrumbs.push(parent_page);
      }
      this.breadcrumbs.push(current_page);
    },

    getParentLinkIfAny() {
      let pathArray = this.$route.path.split("/");
      if (pathArray.length > 2) {
        let parent_route_link = this.$router.options.routes.filter((obj) => {
          return obj.name === pathArray[1];
        })[0];
        return parent_route_link;
      } else {
        return null;
      }
    },

    getCurrentLink() {
      return this.$route;
    },

    getLinkText(link) {
      return this.$t("global.pages_titles." + link.name);
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0.5em 0;
}
</style>