var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs'),_c('v-container',{class:{
      'mt-4 mb-5': _vm.$vuetify.breakpoint.smAndDown,
      'mt-7 mb-11': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('base-subheading',{staticClass:"display-1",class:{
        'mb-5': _vm.$vuetify.breakpoint.smAndDown,
        'mb-11': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._v(" "+_vm._s(_vm.$t('pages_specific.products_page.title'))+" ")]),_c('v-row',_vm._l((_vm.$t(
          'pages_specific.products_page.products_highlights'
        )),function(item,i){return _c('div',{key:i,staticClass:"review col-12 col-md-4 pb-2",class:{
          'pl-2 pr-2  mt-0': _vm.$vuetify.breakpoint.xsOnly,
          'col-12 pl-4 pr-4': _vm.$vuetify.breakpoint.width < 360,
        }},[_c('card',{staticClass:"pa-4 pb-0",attrs:{"outlined":_vm.$vuetify.breakpoint.mdAndUp,"elevation_0":!_vm.$vuetify.breakpoint.mdAndUp}},[_c('img',{staticClass:"restrict-height px-2",attrs:{"alt":item.product_text,"src":require('@/assets/' + item.image)}}),_c('h4',{staticClass:"h4_cards"},[_vm._v(" "+_vm._s(item.product_text)+" ")])])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }