import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#f1d135',
        secondary: '#083759',
        background: "#ffffff",
        accent: '#f1d135', //'#010f1c',
        dark: '#15191a', //#0f1d29
        verylight: '#fffced',
        textgrey: '#686868'
      },
    },
  },
})
