import Vue from "vue";
import Vuex from "vuex";
import router from "./../router/index.js";

Vue.use(Vuex);

function routes_to_items() {
  var items = [];
  const routes = router.options.routes;
  for (var i = routes.length - 1; i >= 0; i--) {
    const route = routes[i];

    items.push({
      href: route.path,
      name: route.name,
      children: transformRouteChildren(route),
      hide_children_in_app_bar: route.hide_children_in_app_bar ? true : false,
    });
  }
  return items;
}

function transformRouteChildren(route) {
  if (route.children) {
    var transformed_children = [];
    for (const child of route.children) {
      transformed_children.push({
        href: child.path,
        name: child.name,
        children: null,
      });
    }
    return transformed_children;
  }
  return null;
}

export default new Vuex.Store({
  state: {
    drawer: false,
    items: routes_to_items(),
  },
  getters: {
    links: (state) => {
      return state.items;
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),
  },
  actions: {},
});
