var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs'),_c('v-container',{class:{
      'mt-3 mb-2': _vm.$vuetify.breakpoint.smAndDown,
      'mt-5 mb-15': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('v-row',{staticClass:"text-center"},_vm._l((_vm.$t(_vm.path_in_translations + '.details_page.tiles')),function(item,i){return _c('div',{key:i,staticClass:"step col-6 col-lg-4",class:{
          'col-12 py-2 px-4': _vm.$vuetify.breakpoint.xsOnly,
        }},[_c('v-card',{staticClass:"px-5 pt-2 text-justify",attrs:{"elevation":0,"outlined":true}},[_c('div',{ref:'card_inner_div_' + i,refInFor:true},[_c('h4',{staticClass:"h4_cards mt-1 text-center",class:{
                'text-h6': _vm.$vuetify.breakpoint.smOnly,
                'body-1 font-weight-medium': _vm.$vuetify.breakpoint.xsOnly,
              }},[_vm._v(" "+_vm._s(item["title"])+" ")]),_vm._l((item['p']),function(item_p,i){return _c('p',{key:'p_' + i,staticClass:"pb-1"},[_vm._v(" "+_vm._s(item_p)+" ")])})],2)])],1)}),0),(_vm.custom_part_id == 'radio_materials')?_c('radio-materials'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }