var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-5"},[(!_vm.is_image_on_right)?_c('div',{staticClass:"col-lg-4 text-left mt-3"},[_c('img',{class:{
        'limit-height-14':
          _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
        'limit-height-10': _vm.$vuetify.breakpoint.xsOnly,
        'pr-5': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"alt":_vm.$t(_vm.path_in_translations + '.title'),"title":_vm.$t(_vm.path_in_translations + '.title'),"src":require('@/assets/' + _vm.$t(_vm.path_in_translations + '.image'))}})]):_vm._e(),_c('div',{staticClass:"col-lg-8 desc"},[_c('base-subheading',[_vm._v(_vm._s(_vm.$t(_vm.path_in_translations + ".title")))]),_c('div',{class:{
        'mt-5 mb-0': _vm.$vuetify.breakpoint.smAndDown,
        'mt-5 mb-7': _vm.$vuetify.breakpoint.mdAndUp,
      }},[_vm._l((_vm.$t(_vm.path_in_translations + '.p')),function(item,i){return _c('p',{key:'p_' + i},[_vm._v(" "+_vm._s(item)+" ")])}),(_vm.$te(_vm.path_in_translations + '.p_small_text'))?_c('div',_vm._l((_vm.$t(_vm.path_in_translations + '.p_small_text')),function(item,i){return _c('p',{key:'ps_' + i,staticClass:"mb-0 text-body-2"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()],2),(_vm.link_url && null != _vm.link_url)?_c('v-btn',{staticClass:"text-none font-large accent",class:{
        'mt-5 mb-3': _vm.$vuetify.breakpoint.smAndDown,
        'mb-6': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"large":"","color":"dark","text":"","to":_vm.link_url}},[_vm._v(" "+_vm._s(_vm.$t("global.learn_more"))+" ")]):_vm._e()],1),(_vm.is_image_on_right)?_c('div',{staticClass:"col-lg-4 mt-3",class:{
      'text-right': _vm.$vuetify.breakpoint.lgAndUp,
    }},[_c('img',{class:{
        'limit-height-14':
          _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
        'limit-height-10': _vm.$vuetify.breakpoint.xsOnly,
        'pl-5': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"alt":_vm.$t(_vm.path_in_translations + '.title'),"title":_vm.$t(_vm.path_in_translations + '.title'),"src":require('@/assets/' + _vm.$t(_vm.path_in_translations + '.image'))}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }