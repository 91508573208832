import Vue from "vue";
import Router from "vue-router";
import Home from "../pages_views/Home.vue";
import Services from "../pages_views/Services.vue";
import Products from "../pages_views/Products.vue";
import Docs from "../pages_views/Docs.vue";
import SimpleInfoPage from "../pages_views/SimpleInfoPage.vue";
import ServiceDetailPage from "../pages_views/ServiceDetailPage.vue";
import AboutUs from "../pages_views/AboutUs.vue";
import Realisations from "../pages_views/Realisations.vue";
import RealizationsOptimizationLora from "../pages_views/RealizationsOptimizationLora.vue";
import RealizationsRangeBle from "../pages_views/RealizationsRangeBle.vue";

Vue.use(Router);

function simple_service_details_page(service_id) {
  return {
    path: "/services/" + service_id,
    name: service_id,
    component: ServiceDetailPage,
    props: {
      path_in_translations: "pages_specific.services_page.tiles." + service_id,
    },
  };
}

function get_services_details_pages() {
  const simple_service_details_pages_ids = [
    "electronic_design",
    "mechatronics",
    "it",
    "management",
    "engineering",
    "obsolescence",
    "purchase",
    "prototyping",
    "validation",
  ];

  const specific_service_details_pages_info = [
    { id: "radio", custom_part_id: "radio_materials" },
  ];

  var services_details_pages = [];

  for (const page_id of simple_service_details_pages_ids) {
    services_details_pages.push(simple_service_details_page(page_id));
  }

  for (const page_info of specific_service_details_pages_info) {
    const specific_page = simple_service_details_page(page_info["id"]);
    specific_page["props"]["custom_part_id"] = page_info["custom_part_id"];
    services_details_pages.push(specific_page);
  }

  return services_details_pages;
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/about",
      name: "aboutUs",
      component: AboutUs,
      props: { path_in_translations: "pages_specific.about_us_page" },
    },
    {
      path: "/docs",
      name: "docs",
      component: Docs,
    },
    {
      path: "/realizations",
      name: "realizations",
      component: Realisations,
      hide_children_in_app_bar: true,
      children: [
        {
          path: "/realizations/optimization",
          name: "optimization_lora",
          component: RealizationsOptimizationLora,
        },
        {
          path: "/realizations/range",
          name: "range_ble",
          component: RealizationsRangeBle,
        },
      ],
    },
    {
      path: "/products",
      name: "products",
      component: Products,
    },
    {
      path: "/services",
      name: "services",
      component: Services,
      hide_children_in_app_bar: true,
      children: get_services_details_pages(),
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
  ],
});

export default router;
