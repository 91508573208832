const servicesCategories = [
    {
        id: "project_study",
        icon: "mdi-math-compass",
        learn_more_route: "services/project_study"
    },
    {
        id: "development",
        icon: "mdi-cogs",
        children: [
            {
                id: "electronic_design",
                icon: "mdi-chip",
                learn_more_route: "services/electronic_design"
            },
            {
                id: "mechanical_integration",
                icon: "mdi-ruler-square",
            },
            {
                id: "radio_antennas",
                icon: "mdi-radio-tower",
            },
            {
                id: "software_development",
                icon: "mdi-laptop",
            }
        ]
    },    
    {
        id: "industrialization",
        icon: "mdi-robot-industrial",
    },
    {
        id: "manufacturing",
        icon: "mdi-factory",
    },
]

const services_tiles_ids = [
    'electronic_design',
    'embedded',
    'mechatronics',
    'ai',
    'radio',
    "robotics",
    "mobile",
    "it",
    "management",    
    "engineering",
    "obsolescence",
    "purchase",
    "prototyping",
    "validation",
    "industrialization"   
]

export {
    servicesCategories, services_tiles_ids
}
