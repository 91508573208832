var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-bottom"},[_c('v-container',{staticClass:"steps",class:{
      'mt-3 mb-2': _vm.$vuetify.breakpoint.smAndDown,
      'mt-5 mb-5': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('base-subheading',{staticClass:"display-1 mb-8"},[_vm._v(" "+_vm._s(_vm.$t("global.pages_titles.services"))+" ")]),_c('v-row',{staticClass:"text-center"},_vm._l((_vm.items),function(service_id,i){return _c('div',{key:i,staticClass:"step col-6 col-lg-4",class:{
          'col-12 py-2 px-4': _vm.$vuetify.breakpoint.xsOnly,
        }},[_c('router-link',{attrs:{"to":_vm.get_service_detailed_page(service_id),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('card',{attrs:{"height":_vm.$vuetify.breakpoint.smAndUp ? _vm.card_height : null},on:{"click":function($event){return _vm.navigate_if_page_exists(navigate, $event, service_id)}}},[_c('div',{ref:'card_inner_div_' + i,refInFor:true},[_c('h4',{staticClass:"h4_cards mt-1",class:{
                  'text-h6': _vm.$vuetify.breakpoint.smOnly,
                  'body-1 font-weight-medium': _vm.$vuetify.breakpoint.xsOnly,
                  'text-decoration-underline': _vm.page_defined_as_route(
                    service_id
                  ),
                }},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.services_page.tiles." + service_id + ".title" ))+" ")]),_c('p',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.services_page.tiles." + service_id + ".text" ))+" ")])])])]}}],null,true)})],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }