<template>
  <div class="row mt-5 px-5">
    <div class="col-lg-8 desc">
      <h4
        class="h4_cards mt-1"
        :class="{
          'text-h6': $vuetify.breakpoint.smOnly,
          'body-1 font-weight-medium': $vuetify.breakpoint.xsOnly,
        }"
      >
        {{
          $t(
            "pages_specific.services_page.tiles.radio.details_page.custom_part.title"
          )
        }}
      </h4>

      <ul>
        <li
          v-for="(item_l1, i) in $t(
            'pages_specific.services_page.tiles.radio.details_page.custom_part.list'
          )"
          :key="'l1_' + i"
        >
          <span class="font-weight-medium pr-1">{{ item_l1.name }}</span>
          <span v-if="item_l1.details != null">{{ item_l1.details }}</span>

          <ul v-if="item_l1.list != null">
            <li v-for="(item_l2, i) in item_l1.list" :key="'l2_' + i">
              {{ item_l2 }}
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div
      class="col-lg-4 mt-3"
      :class="{
        'text-right': $vuetify.breakpoint.lgAndUp,
      }"
    >
      <img
        :class="{
          'limit-height-14':
            $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly,
          'limit-height-10': $vuetify.breakpoint.xsOnly,
          'pl-5': $vuetify.breakpoint.lgAndUp,
        }"
        :src="require('@/assets/images/services/anritsu.png')"
      />
    </div>

    <v-btn
      color="primary"
      class="dark--text mt-10 mb-5"
      :to="'/realizations/optimization#content'"
      >{{
        $t(
          "pages_specific.services_page.tiles.radio.details_page.custom_part.see_more_our_work"
        )
      }}</v-btn
    >
  </div>
</template>

<script>
export default {};
</script>
