<template>
  <div class="border-bottom">
    <v-container
      class="steps"
      :class="{
        'mt-3 mb-2': $vuetify.breakpoint.smAndDown,
        'mt-5 mb-5': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <base-subheading class="display-1 mb-8">
        {{ $t("global.pages_titles.services") }}
      </base-subheading>

      <v-row class="text-center">
        <div
          class="step col-6 col-lg-4"
          :class="{
            'col-12 py-2 px-4': $vuetify.breakpoint.xsOnly,
          }"
          v-for="(service_id, i) in items"
          :key="i"
        >
          <router-link
            :to="get_service_detailed_page(service_id)"
            custom
            v-slot="{ navigate }"
          >
            <card
              @click="navigate_if_page_exists(navigate, $event, service_id)"
              :height="$vuetify.breakpoint.smAndUp ? card_height : null"
            >
              <div :ref="'card_inner_div_' + i">
                <h4
                  class="h4_cards mt-1"
                  :class="{
                    'text-h6': $vuetify.breakpoint.smOnly,
                    'body-1 font-weight-medium': $vuetify.breakpoint.xsOnly,
                    'text-decoration-underline': page_defined_as_route(
                      service_id
                    ),
                  }"
                >
                  {{
                    $t(
                      "pages_specific.services_page.tiles." +
                        service_id +
                        ".title"
                    )
                  }}
                </h4>
                <p class="pb-1">
                  {{
                    $t(
                      "pages_specific.services_page.tiles." +
                        service_id +
                        ".text"
                    )
                  }}
                </p>
              </div>
            </card>
          </router-link>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Card from "../custom_styling/Card.vue";
import { services_tiles_ids } from "@/data_for_components/services_page";

export default {
  components: { Card },

  data: () => ({
    items: services_tiles_ids,
    card_height: "11em",
  }),

  methods: {
    navigate_if_page_exists(navigate_function, event, service_id) {
      if (this.page_defined_as_route(service_id)) {
        navigate_function(event);
      } else {
        // page not defined
      }
    },

    get_service_detailed_page(service_id) {
      return "/services/" + service_id; // + "#content";
    },

    page_defined_as_route(service_id) {
      const router_page_info = this.$router.resolve(
        this.get_service_detailed_page(service_id)
      );
      return null != router_page_info.resolved.name;
    },
  },
};
</script>

<style scoped>
.v-card {
  padding: 1em 1em 0 1em;
}

.steps {
  margin-top: 2em;
}

.step {
  min-width: min-content;
}
</style>
