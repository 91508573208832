<template>
  <div>
    <breadcrumbs />

    <v-container :class="{
      'mt-3 mb-2 px-5': $vuetify.breakpoint.smAndDown,
      'mt-5 mb-15': $vuetify.breakpoint.mdAndUp,
    }">
      <base-subheading class="mb-10 display-1">
        {{ $t("pages_specific.realizations_page.range.title") }}
      </base-subheading>

      <text-floating-image class="mt-5" :image_path="
        $t('pages_specific.realizations_page.range.details_page.part_1.image')
      " :paragraphs="
  $t('pages_specific.realizations_page.range.details_page.part_1.p')
" />

      <div class="row">
        <div class="mt-10 col-md-4 px-md-9" :class="{ 'text-justify': $vuetify.breakpoint.mdAndUp }" v-for="(col, i) in $t(
          'pages_specific.realizations_page.range.details_page.part_2.cols'
        )" :key="'c' + i">
          <base-subheading class="mb-10" :class="{ 'text-center': $vuetify.breakpoint.mdAndUp }">
            {{ col.title }}
          </base-subheading>

          <text-floating-image class="mt-5" :image_path="col.image" :paragraphs="col.p" :disable_float="true" />
        </div>
      </div>

      <div class="mt-10">
        <base-subheading class="mb-10 display-1">
          {{
              $t(
                "pages_specific.realizations_page.range.details_page.part_3.title"
              )
          }}
        </base-subheading>

        <p v-for="(p, i) in $t(
          'pages_specific.realizations_page.range.details_page.part_3.p'
        )" :key="'p3' + i" v-html="p" />
      </div>
    </v-container>

    <v-divider />
  </div>
</template>
  
<script>
import TextFloatingImage from "../components/app_specific/TextFloatingImage.vue";

export default {
  components: {
    Breadcrumbs: () => import("@/components/core/Breadcrumbs.vue"),
    TextFloatingImage,
  },
};
</script>
  
<style scoped>
.center-block {
  display: block;
  margin: auto;
}
</style>