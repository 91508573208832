<template>
  <div class="row">
    <div>
      <img
        :height="'270'"
        class="pb-5"
        :class="{
          'float-left pr-7': is_image_floating,
          'center-block': !is_image_floating && md_and_up
        }"
        :src="require('@/assets/' + image_path)"
      />

      <p v-for="(p, i) in paragraphs" :key="'p1' + i">
        {{ p }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image_path: {
      type: String,
      default: null,
    },
    paragraphs: {
      type: Array,
      default: [],
    },
    disable_float: {
      type: Boolean,
      default: false,
    },
  },
  
  computed: {
    is_image_floating() {
      return (this.md_and_up && !this.disable_float)
    },
    
    md_and_up() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
};
</script>

<style scoped>
.center-block {
  display: block;
  margin: auto;
}
</style>