var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs'),_c('v-container',{class:{
      'mt-3 mb-2 px-5': _vm.$vuetify.breakpoint.smAndDown,
      'mt-5 mb-15': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('base-subheading',{staticClass:"mb-10 display-1"},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.realizations_page.optimization.title"))+" ")]),_c('text-floating-image',{staticClass:"mt-5",attrs:{"image_path":_vm.$t(
          'pages_specific.realizations_page.optimization.details_page.part_1.image'
        ),"paragraphs":_vm.$t(
          'pages_specific.realizations_page.optimization.details_page.part_1.p'
        )}}),_c('div',{staticClass:"mt-5 row"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.realizations_page.optimization.details_page.part_2.p" ))+" ")]),_c('div',{staticClass:"col-lg-6"},[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.realizations_page.optimization.details_page.part_2.table_1.title" ))+" ")]),_c('ul',_vm._l((_vm.$t(
              'pages_specific.realizations_page.optimization.details_page.part_2.table_1.rows'
            )),function(row,i){return _c('li',{key:'t1_r' + i},[_c('div',[_vm._v(" "+_vm._s(row.material)+" ")]),_c('table',{staticClass:"antenna_table"},[_c('tr',[_c('td',[_vm._v(" "+_vm._s(row.efficiency)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.vswr)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.rl)+" ")])])])])}),0)]),_c('div',{staticClass:"col-lg-6"},[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.realizations_page.optimization.details_page.part_2.table_2.title" ))+" ")]),_c('ul',_vm._l((_vm.$t(
              'pages_specific.realizations_page.optimization.details_page.part_2.table_2.rows'
            )),function(row,i){return _c('li',{key:'t2_r' + i},[_c('div',[_vm._v(" "+_vm._s(row.material)+" ")]),_c('table',{staticClass:"antenna_table"},[_c('tr',[_c('td',[_vm._v(" "+_vm._s(row.efficiency)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.vswr)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.rl)+" ")])])])])}),0)])]),_c('div',{staticClass:"mt-10 row"},[_c('base-subheading',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.realizations_page.optimization.details_page.part_3.title" ))+" ")]),_c('div',{staticClass:"desc"},_vm._l((_vm.$t(
            'pages_specific.realizations_page.optimization.details_page.part_3.p'
          )),function(p,i){return _c('p',{key:'p3' + i,domProps:{"innerHTML":_vm._s(p)}})}),0)],1)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }