<template>
  <div>
    <breadcrumbs />

    <v-container
      :class="{
        'mt-3 mb-2 px-5': $vuetify.breakpoint.smAndDown,
        'mt-5 mb-15': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <base-subheading class="mb-10 display-1">
        {{ $t("pages_specific.realizations_page.optimization.title") }}
      </base-subheading>

      <text-floating-image
        class="mt-5"
        :image_path="
          $t(
            'pages_specific.realizations_page.optimization.details_page.part_1.image'
          )
        "
        :paragraphs="
          $t(
            'pages_specific.realizations_page.optimization.details_page.part_1.p'
          )
        "
      />

      <div class="mt-5 row">
        <p>
          {{
            $t(
              "pages_specific.realizations_page.optimization.details_page.part_2.p"
            )
          }}
        </p>

        <div class="col-lg-6">
          <p class="text-center font-weight-bold">
            {{
              $t(
                "pages_specific.realizations_page.optimization.details_page.part_2.table_1.title"
              )
            }}
          </p>

          <ul>
            <li
              v-for="(row, i) in $t(
                'pages_specific.realizations_page.optimization.details_page.part_2.table_1.rows'
              )"
              :key="'t1_r' + i"
            >
              <div>
                {{ row.material }}
              </div>

              <table class="antenna_table">
                <tr>
                  <td>
                    {{ row.efficiency }}
                  </td>
                  <td>
                    {{ row.vswr }}
                  </td>
                  <td>
                    {{ row.rl }}
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </div>

        <div class="col-lg-6">
          <p class="text-center font-weight-bold">
            {{
              $t(
                "pages_specific.realizations_page.optimization.details_page.part_2.table_2.title"
              )
            }}
          </p>

          <ul>
            <li
              v-for="(row, i) in $t(
                'pages_specific.realizations_page.optimization.details_page.part_2.table_2.rows'
              )"
              :key="'t2_r' + i"
            >
              <div>
                {{ row.material }}
              </div>

              <table class="antenna_table">
                <tr>
                  <td>
                    {{ row.efficiency }}
                  </td>
                  <td>
                    {{ row.vswr }}
                  </td>
                  <td>
                    {{ row.rl }}
                  </td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-10 row">
        <base-subheading class="display-1">
          {{
            $t(
              "pages_specific.realizations_page.optimization.details_page.part_3.title"
            )
          }}
        </base-subheading>

        <div class="desc">
          <p
            v-for="(p, i) in $t(
              'pages_specific.realizations_page.optimization.details_page.part_3.p'
            )"
            :key="'p3' + i"
            v-html="p"
          />
        </div>
      </div>
    </v-container>

    <v-divider />
  </div>
</template>

<script>
import TextFloatingImage from "../components/app_specific/TextFloatingImage.vue";

export default {
  components: {
    Breadcrumbs: () => import("@/components/core/Breadcrumbs.vue"),
    TextFloatingImage,
  },
};
</script>

<style scoped>
.antenna_table {
  width: 100%;
  text-align: center;
}
</style>
