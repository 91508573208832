<template>
  <div>
    <router-view v-if="isFinalChildPage"></router-view>
    <div v-else>
      <breadcrumbs />

      <v-divider />

      <services-tiles-with-texts-and-links />

      <contact :footer_version="true" />
    </div>
  </div>
</template>

<script>
import { servicesCategories } from "../data_for_components/services_page";
import { mapGetters } from "vuex";
import ServicesTilesWithTextsAndLinks from "../components/app_specific/ServicesTilesWithTextsAndLinks.vue";
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
import Contact from "./Contact.vue";

export default {
  components: {
    Breadcrumbs,
    ServicesTilesWithTextsAndLinks,
    Contact,
  },

  data: () => ({
    servicesCategories: servicesCategories,
  }),

  computed: {
    ...mapGetters(["links"]), // routes transformed to links (with children)

    isFinalChildPage() {
      return this.child_links.length == 0;
    },

    child_links() {
      let link = this.links.filter((obj) => {
        return obj.name === this.$route.name;
      });
      if (link.length == 1) {
        // found
        return link[0].children;
      } else {
        return [];
      }
    },
  },
};
</script>
