import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/base";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n.js";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.devtools = false;
Vue.config.productionTip = false;


Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCC3I9bfAaIylQQcYjDcoTMj9bz2Pj-okY",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
