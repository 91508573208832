<template>
  <div>
    <router-view v-if="isFinalChildPage"></router-view>
    <div v-else>
      <breadcrumbs />
      <v-container
        :class="{
          'mt-3 mb-2': $vuetify.breakpoint.smAndDown,
          'mt-5 mb-5': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <base-subheading class="display-1">
          {{ $t("pages_specific.realizations_page.title") }}
        </base-subheading>

        <title-text-image-link-transformed-from-locales
          :path_in_translations="
            'pages_specific.realizations_page.optimization'
          "
          :link_url="'/realizations/optimization#content'"
        />

        <title-text-image-link-transformed-from-locales
          class="mt-10"
          :path_in_translations="'pages_specific.realizations_page.range'"
          :link_url="'/realizations/range#content'"
          :is_image_on_right="true"
        />

        <title-text-image-link-transformed-from-locales
          class="mt-10"
          :path_in_translations="'pages_specific.realizations_page.integration'"
        />
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TitleTextImageLinkTransformedFromLocales from "../components/app_specific/TitleTextImageLinkTransformedFromLocales.vue";

export default {
  components: {
    Breadcrumbs: () => import("@/components/core/Breadcrumbs.vue"),
    TitleTextImageLinkTransformedFromLocales,
  },

  computed: {
    ...mapGetters(["links"]), // routes transformed to links (with children)

    isFinalChildPage() {
      return this.child_links.length == 0;
    },

    child_links() {
      let link = this.links.filter((obj) => {
        return obj.name === this.$route.name;
      });
      if (link.length == 1) {
        // found
        return link[0].children;
      } else {
        return [];
      }
    },
  },
};
</script>
