<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-view />

    <core-footer />
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreFooter: () => import("@/components/core/Footer"),
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreView: () => import("@/components/core/View"),
  },

  watch: {
    $route: async function () {
      // Double $vuetify.goTo(0) is
      // a hack to fix vuetify goto bug.
      // Delete the line above if it is fixed in the later versions of vuetify
      await this.$vuetify.goTo(0, { duration: 0 });
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
@import "./assets/styles/styles.css";
</style>
