<template>
  <div>
    <breadcrumbs />
    <!-- The page specfic content should be placed here -->
    <v-container
      :class="{
        'mt-3 mb-2': $vuetify.breakpoint.smAndDown,
        'mt-5 mb-15': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <!-- <base-subheading class="display-1 mb-8">
        {{ $t(path_in_translations + ".title") }}
      </base-subheading> -->

      <v-row class="text-center">
        <div
          class="step col-6 col-lg-4"
          :class="{
            'col-12 py-2 px-4': $vuetify.breakpoint.xsOnly,
          }"
          v-for="(item, i) in $t(path_in_translations + '.details_page.tiles')"
          :key="i"
        >
          <v-card
            :elevation="0"
            :outlined="true"
            class="px-5 pt-2 text-justify"
          >
            <div :ref="'card_inner_div_' + i">
              <h4
                class="h4_cards mt-1 text-center"
                :class="{
                  'text-h6': $vuetify.breakpoint.smOnly,
                  'body-1 font-weight-medium': $vuetify.breakpoint.xsOnly,
                }"
              >
                {{ item["title"] }}
              </h4>
              <p class="pb-1" v-for="(item_p, i) in item['p']" :key="'p_' + i">
                {{ item_p }}
              </p>
            </div>
          </v-card>
        </div>
      </v-row>

      <radio-materials v-if="custom_part_id == 'radio_materials'" />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
import Card from "../components/custom_styling/Card.vue";
import RadioMaterials from "../components/services_pages/RadioMaterials.vue";
export default {
  props: {
    path_in_translations: {
      type: String,
      default: "",
    },
    custom_part_id: {
      type: String,
      default: null,
    },
  },

  components: {
    Breadcrumbs,
    Card,
    RadioMaterials,
  },
};
</script>
