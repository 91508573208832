var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-5 px-5"},[_c('div',{staticClass:"col-lg-8 desc"},[_c('h4',{staticClass:"h4_cards mt-1",class:{
        'text-h6': _vm.$vuetify.breakpoint.smOnly,
        'body-1 font-weight-medium': _vm.$vuetify.breakpoint.xsOnly,
      }},[_vm._v(" "+_vm._s(_vm.$t( "pages_specific.services_page.tiles.radio.details_page.custom_part.title" ))+" ")]),_c('ul',_vm._l((_vm.$t(
          'pages_specific.services_page.tiles.radio.details_page.custom_part.list'
        )),function(item_l1,i){return _c('li',{key:'l1_' + i},[_c('span',{staticClass:"font-weight-medium pr-1"},[_vm._v(_vm._s(item_l1.name))]),(item_l1.details != null)?_c('span',[_vm._v(_vm._s(item_l1.details))]):_vm._e(),(item_l1.list != null)?_c('ul',_vm._l((item_l1.list),function(item_l2,i){return _c('li',{key:'l2_' + i},[_vm._v(" "+_vm._s(item_l2)+" ")])}),0):_vm._e()])}),0)]),_c('div',{staticClass:"col-lg-4 mt-3",class:{
      'text-right': _vm.$vuetify.breakpoint.lgAndUp,
    }},[_c('img',{class:{
        'limit-height-14':
          _vm.$vuetify.breakpoint.smOnly || _vm.$vuetify.breakpoint.mdOnly,
        'limit-height-10': _vm.$vuetify.breakpoint.xsOnly,
        'pl-5': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"src":require('@/assets/images/services/anritsu.png')}})]),_c('v-btn',{staticClass:"dark--text mt-10 mb-5",attrs:{"color":"primary","to":'/realizations/optimization#content'}},[_vm._v(_vm._s(_vm.$t( "pages_specific.services_page.tiles.radio.details_page.custom_part.see_more_our_work" )))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }