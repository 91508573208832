<template>
  <div>
    <services-steps-cards-with-icons />
    <!-- <about /> -->
    <!-- <v-divider /> -->
    <cir-logo-with-text />
    <v-divider />
    <reviews />
    <partners />
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {
    ServicesStepsCardsWithIcons: () => import("@/components/home_page/ServicesStepsCardsWithIcons"),
    About: () => import("@/components/home_page/About"),
    cirLogoWithText: () => import("@/components/home_page/cirLogoWithText"),
    Reviews: () => import("@/components/home_page/Reviews"),
    Partners: () => import("@/components/home_page/Partners"),
  },
};
</script>
