<template>
  <div>
    <breadcrumbs />

    <v-container
      :class="{
        'mt-4 mb-5': $vuetify.breakpoint.smAndDown,
        'mt-7 mb-11': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <base-subheading
        class="display-1"
        :class="{
          'mb-5': $vuetify.breakpoint.smAndDown,
          'mb-11': $vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ $t('pages_specific.products_page.title') }}
      </base-subheading>

      <v-row>
        <div
          v-for="(item, i) in $t(
            'pages_specific.products_page.products_highlights'
          )"
          :key="i"
          class="review col-12 col-md-4 pb-2"
          :class="{
            'pl-2 pr-2  mt-0': $vuetify.breakpoint.xsOnly,
            'col-12 pl-4 pr-4': $vuetify.breakpoint.width < 360,
          }"
        >
          <card
            class="pa-4 pb-0"
            :outlined="$vuetify.breakpoint.mdAndUp"
            :elevation_0="!$vuetify.breakpoint.mdAndUp"
          >
            <img
              class="restrict-height px-2"
              :alt="item.product_text"
              :src="require('@/assets/' + item.image)"
            />

            <h4 class="h4_cards">
              {{ item.product_text }}
            </h4>
          </card>
        </div>
      </v-row>
    </v-container>

    <!-- <title-text-list-text-transformed-from-locales-in-tabs
      :data="productsCategories"
      path_in_translations="pages_specific.products_page.categories"
    /> -->
  </div>
</template>

<script>
import Breadcrumbs from "../components/core/Breadcrumbs.vue";
import Card from "../components/custom_styling/Card.vue";
import { productsCategories } from "../data_for_components/products_page";

export default {
  components: {
    TitleTextListTextTransformedFromLocalesInTabs: () =>
      import(
        "@/components/app_specific/TitleTextListTextTransformedFromLocalesInTabs"
      ),
    Breadcrumbs,
    Card,
  },

  data: () => ({
    productsCategories: productsCategories,
  }),
};
</script>

<style scoped>
.review {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  text-align: center;
}
.restrict-height {
  max-height: 200px;
}
</style>