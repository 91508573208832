<template>
  <div>
    <breadcrumbs v-if="!footer_version" />
    <v-container
      class="mb-6"
      :class="{
        'mt-3': $vuetify.breakpoint.smAndDown,
        'mt-10': $vuetify.breakpoint.mdAndUp && footer_version,
        'mt-5': $vuetify.breakpoint.mdAndUp && !footer_version,
      }"
    >
      <v-row>
        <v-col cols="12" md="4">
          <visit-card :footer_version="footer_version" />
        </v-col>
        <v-col cols="12" md="8">
          <contact-form :footer_version="footer_version" />
        </v-col>
      </v-row>
    </v-container>

    <v-divider />
    <iframe
      v-if="!footer_version"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.6904668253214!2d7.747568315619319!3d48.57747697926088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c8fc0c0d480f%3A0x8123fe6cb0af676f!2sStrataggem!5e0!3m2!1sen!2sfr!4v1611167342451!5m2!1sen!2sfr"
      width="100%"
      height="300"
      frameborder="0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
    />

    <!-- <GmapMap
      v-if="!footer_version"
      :center="{ lat: 48.57741, lng: 7.74935 }"
      :zoom="15"
      map-type-id="terrain"
      style="width: 100%; height: 300px"
    >
    </GmapMap> -->
  </div>
</template>

<script>
export default {
  props: {
    footer_version: { type: Boolean, default: false },
  },

  components: {
    VisitCard: () => import("@/components/contact/VisitCard"),
    ContactForm: () => import("@/components/contact/ContactForm"),
    Breadcrumbs: () => import("@/components/core/Breadcrumbs.vue"),
  },

};
</script>